/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-06-28",
    versionChannel: "nightly",
    buildDate: "2024-06-28T00:05:24.904Z",
    commitHash: "02f15fd27c1e73cdb5c573cf3283448ea0b7892c",
};
